@import './Fonts';
@import "~quill/dist/quill.core.css";

#root {
  height: 100%;
}

.script-tag {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.radio-option {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: .5em;
  width: calc(50% - 3px);
  padding: 1em 1.5em;
  background-color: #fff;

  &.selected {
    border-color: red;
  }

  &.mb {
    margin-bottom: 6px;
  }

  & .emoji {
    margin-right: 1em;
  }
}

.warning-btn {
  background-color: #F0AD4E;
  color: white;

  &:hover {
    background-color: #F0AD4E;
    color: white;
    border-color: #F0AD4E;
  }
}

.timeline-box {
  padding: 25px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 2px 3px 0 rgb(0 0 0 / 10%);
  text-align: left;
  max-width: 400px;
  min-width: 400px;
  color: #79797c;

  &:hover {
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 2px 4px 1px rgb(0 0 0 / 14%);
  }
}

.icon-library {
  opacity: .5;
  position: relative;

  & svg {
    border-radius: 5px;
  }

  &.selected {
    opacity: 1;

    &:after {
      width: 15px;
    }
  }


  &:after {
    content: "";
    background-color: #2494D1;
    width: 0px;
    height: 6px;
    position: absolute;
    bottom: -5px;
    transform: translateX(-50%);
    left: 50%;
    border-radius: 5px;
    transition: all 0.2s;
  }

  &:hover {
    opacity: 1;
  }
}

.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;
  box-shadow: 0 0 6px rgb(0 0 0 / 25%);
  border-radius: 8px;

  p {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    margin-bottom: 5px;
  }

  input {
    margin-bottom: 5px;
  }
}

.behavior-btn {
  background: #F7F7F7;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  text-align: center;
  max-width: 150px;
  color: black;
  cursor: pointer;
  font-size: 12px;

  span {
    text-transform: uppercase;
  }

  &.selected {
    background-color: #2494D1;
    color: white;
  }
}

.copy-btn {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  transition: color 0.3s;
}

.copy-btn:hover {
  color: #1890ff;
}

.form-multiple {
  .ant-form-item {
    margin-bottom: 10px;
  }
}

h1 {
  color: #2494D1;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 161.8%;
  margin-bottom: 0;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 161.8%;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 161.8%;
}

h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 161.8%;
}

.logo {
  margin-right: 90px;
}

.mmc-logo {
  position: absolute;
  width: 173px;
  height: 43px;
  right: 84px;
  bottom: 54px;
}

.log-description {
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 161.8%;
  color: #FFFFFF;

  /* Ombre words */

  text-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
}

.log-input {
  width: 100%;
  border-color: #E9E9E9;
}

.header-menu-item a {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  line-height: 24px;
  color: white;
}

.ant-menu-item-selected {
  text-decoration: underline;
  text-underline-offset: 12px;
  text-decoration-thickness: 2px;
}

.selected-view {
  border-color: #F0F0F0;
  background-color: #F0F0F0;
  color: #2494D1;
}

.ant-card-body {
  padding-bottom: 16px;
  padding-top: 16px;
}


.account-card-grid {
  margin-top: 2em;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: center;
}

.message-card-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 400px);
  justify-content: center;
}

.editor-sidebar {
  width: 80px;
  margin-bottom: 16px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.45);

  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.action-btn {
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.055em;
}

.secondary-actions {
  width: 64px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 8px;

  .action-btn {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
    border: none;


    &.active {
      background: #2494D1;
      border-radius: 8px;
    }
  }
}

.insert-title {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  position: relative;
}

.insert {
  width: 300px;
  height: 400px;
  overflow: auto;
}

.insert-grid {
  display: grid;
  grid-template-rows: 80px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.insert-btn {
  cursor: pointer;
  height: 80px;
  background: #F7F7F7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }
}

.text-example {
  position: relative;
  background-color: #E2E2E2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 15px 10px;
  cursor: pointer;
  margin: 10px 0;

  &:hover {
    background-color: #F0F0F0;
  }

  p {
    margin: 0;
  }

  .font {
    font-size: 11px;
    left: 10px;
    position: absolute;
    top: 0;
    color: #2494D1;
  }
}

/** Quill editor */

.ql-editor {
  padding: 0;
  overflow: visible;
}

.ql-container.ql-snow {
  border: none;
}

.ql-snow .ql-picker.ql-font {
  width: 200px;
}


.container_overflow {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.last-save {
  position: absolute;
  text-align: right;
  color: #2494D1;
  font-size: 12px;
  right: 0;
  top: 0;
}


.user-form {

  position: absolute;

  input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all .3s;
    transition: all .3s;
    height: 100%;
    min-height: 25px;
  }

  input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2);
  }

  input[type=checkbox] {
    margin-right: .4rem;
    width: 16px;
    height: auto;
  }

  label {
    font-size: 16px;
    color: black;
  }

}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

.m-10 {
  margin: 10px;
}

.m-1em {
  margin: 2rem;
}

.mb {
  margin-bottom: 1em;
}

.mb-lg {
  margin-bottom: 2em;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt {
  margin-top: 1em;
}

.mt-2em {
  margin-top: 2rem;
}

.p-10 {
  padding: 10px;
}

.radius-5 {
  border-radius: 5px;
}

.d-table {
  display: table;
}

.pointer {
  cursor: pointer;
}

.h-100per {
  height: 100%;
}

.h-90per {
  height: 90%;
}

.h-10per {
  height: 10%;
}

.back-grey-light {
  background-color: #f9f9f9;
}

.ant-layout-header.header {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  height: 84px;
  padding: 0 84px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
}

.ant-card-body {
  height: 100%;
}

//.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
//  background-color: transparent;
//}

.mainLayout {
  min-height: 100%;
}

.site-layout-background {
  background: #fff;
}

.card-style {
  box-shadow: 0 4px 16px rgb(0 0 0 / 25%);
  border-radius: 8px;
}

.ant-modal-title {
  text-align: center;
}

.ant-modal-close-x {
  color: white;
}

.board {
  position: relative;
  background-color: #F0F0F0;
  display: flex;
  flex: 1;
  overflow: auto;
  width: 100%;
  padding: 2em;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#container,
#screenshot {
  box-sizing: content-box;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }
}

.tabContent {
  height: 100%;
  //max-height: 400px;
  overflow: auto;
  background-color: white;
  padding: 1em;
}

.layersContainer {
  padding: 1em;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
}

.layerBox {
  padding: 10px;
  border: 2px solid #E2E2E2;
  background-color: #E2E2E2;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  &.selected {
    border: 2px solid #2494D1;
  }

  &.hover-top:before {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    /* height: 0.5em; */
    border-top: 3px solid #2494D1;
    z-index: 0;
  }

  &.hover-bot:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    /* height: 0.5em; */
    border-top: 3px solid #2494D1;
    z-index: 0;
  }
}

.inter {
  padding: 5px 0;
  position: relative;

  &.dragover:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 3px solid #2494D1;
    z-index: 0;
    transform: translateY(-50%);
  }
}

.pictureGrid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 150px;
}


.layerIcons {
  text-align: center;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
  padding: 1em 0;
}

.iconsGrid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 40px);

  &.color {
    grid-template-columns: repeat(auto-fill, 32px);

  }
}

.icon {
  width: 40px;
  height: 40px;
  border: solid 1px #8d8d8d;
  justify-content: center;
  align-items: center;
  display: flex;
}


.targets {
  //z-index: 1;

  &.editing {
    z-index: 2;
  }
}

.targetNotSelected {
  --moveable-color: none !important;
}


/****** Color Component ******/


.color-container {
  width: 32px;
  height: 32px;
}


.colorSquare {
  border-radius: 5px;
  border: 1px solid rgb(190 190 190 / 80%);
  width: 32px;
  height: 32px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }
}

.colorPicker {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

/****** Home page ******/

.mylist {
  min-height: 350px;
}

// Template page

.wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}

// FIX antd flex problems with board when size of container is to big

.ant-layout,
.ant-layout-content {
  flex: 1;

  .ant-spin-nested-loading {
    flex: auto;
    display: flex;
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    flex: auto;
    width: 100%;
  }
}

// FORMES && LIGNES

.editor-shape-img {
  width: 90px;
  padding-right: 8px;
  object-fit: contain;
}


.iconFinder svg {
  width: 100%;
  height: 100%;
}

.currentColor {
  fill: currentColor;
}

.ant-tooltip {
  //z-index: 1040;
}


.selectedBtn {
  box-shadow: 0 0 2px 2px #2cafe7;
}

.container {
  transition: all ease 0.4s;

  & p {
    line-height: 1.42;
    margin: 0;
  }

  & svg {
    width: 100%;
    height: 100%;
  }
}

.transparent-bg {
  background: #eee;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, .25) 25%, transparent 0, transparent 75%, rgba(0, 0, 0, .25) 0),
    linear-gradient(45deg, rgba(0, 0, 0, .25) 25%, transparent 0, transparent 75%, rgba(0, 0, 0, .25) 0);
  background-position: 0 0, 16px 16px;
  background-size: 32px 32px;
}


// TEMPLATE CARD

.template-tag {
  background-color: #f9f9f9;
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, .2);
}

.template-card {
  flex: 0 0 0 25%;
  width: 25%;
  min-height: 100px;
  max-height: 400px;
  padding: 5px;
  border: 1px solid #f9f9f9;
  border-radius: 2px;

  .template-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: .3s;

    .overlay-btn {
      opacity: 0;
      transition: .3s;
    }
  }

  .template-overlay:hover {
    background-color: rgba(0, 0, 0, .3215686274509804);
  }

  .template-overlay:hover>.overlay-btn {
    opacity: 1;
  }
}

.galery {
  margin: auto;
  max-width: 90%;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .column_galery {
    display: block;
    flex-basis: 10rem;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 1.45rem;

    //padding: 0 4px;
    .img_container {
      width: 100%;
      margin-bottom: 1.45rem;

      .action_img {
        //top: 8px;
      }

      img {
        vertical-align: middle;
        width: 100%;
      }
    }
  }
}

.img_container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
  overflow: hidden;
  position: relative;
}

@media (min-width: 1900px) {
  .galery {
    max-width: 90%;
  }
}

@media (max-width: 849px) {
  .galery {
    padding: 1rem;

    .column_galery {
      .img_container {
        margin-bottom: 1rem;
      }
    }
  }

  .display_on_hover {
    opacity: 1;
  }
}

@media screen and (max-width: 1300px) {
  .template-card {
    flex: 0 0 33.3333%;
    width: 33.3333%;
  }
}

@media screen and (max-width: 1050px) {
  .template-card {
    flex: 0 50%;
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .template-card {
    flex: 100%;
    width: 100%;
  }
}